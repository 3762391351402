import React from 'react';
import { Layout } from 'antd';
import { PhoneOutlined, MailOutlined } from '@ant-design/icons'; // 引入電話圖標

const { Footer: AntFooter } = Layout;

const Footer = () => {
  return (
    <AntFooter style={{ 
      textAlign: 'center', 
      background: '#e0e2e5', 
      boxShadow: '0px -2px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '0 0 10px 10px'
    }}>
      <span>
        <PhoneOutlined style={{ marginRight: '8px' }} /> {/* 添加電話圖標並設置間距 */}
        0907855916
      </span>
      &emsp;
      &emsp;
      <span>
        <MailOutlined style={{ marginRight: '8px' }} /> {/* 添加郵件圖標並設置間距 */}
        kennytechnology2000@gmail.com
      </span>
      <br /> {/* 這裡使用 <br /> 元素來換行 */}
      <span>Kenny Technology©2024</span>
    </AntFooter>
  );
};

export default Footer;
